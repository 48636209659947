<script>
import { mapActions } from 'vuex'

export default {
  name: 'ManagementMsTeams',
  methods: {
    ...mapActions([
      'attemptSaveMsTeamsToken',
      'setFeedback'
    ]),
    getMsTeamsCode () {
      return this.$route.query.code
    }
  },
  created () {
    this.attemptSaveMsTeamsToken(this.getMsTeamsCode()).then((data) => {
    }).finally(() => {
      window.close()
    })
  }
}
</script>

<template>
  <div>:)</div>
</template>
